<!--suppress CssUnknownTarget, CssInvalidPropertyValue -->
<template>
  <div
    v-loading="!showPage"
    class="successPage"
    :class="{'successPage-mobile': !isPc}">
    <tvu-top-header
      v-if="showPage"
      ref="topHeaderRef"
      projectName="TVU networks"
      :globalFunList="['account']"
      :unUseDefaultMultiAccountModule="false"
      :serviceVesion="[]"
      @callback="topHeaderCallback">
    </tvu-top-header>
    <div
      class="contentBox"
      v-if="showPage">
      <div class="titleBox">
        <p class="bigTitle">Welcome to TVU</p>
        <p class="smallTitle">Making every transmission a success.</p>
      </div>
      <div :class="['serviceListBox scrollCss', isCenter ? 'center' : '']">
        <!-- https://tvuservices-dev.tvunetworks.com/tvuuserservice/package.serviceList/images/itemIcons/userserviceItem_icon.png -->
        <div
          class="box-item"
          v-for="(item, index) in serviceList"
          :key="index">
          <a
            v-if="!item.googleFlag"
            :href="getServiceUrl(item)"
            :target="item.publicFlag == '1' ? '_blank' : '_self'"
            @click="isRedirect(item.googleFlag)">
            <img
              :src="`${protocol}//${serviceListUrl}/package.serviceList/images/${serviceImg[item.iconName] || 'Default-Service'}.png`"
              alt="" />
            <p>{{ item.serviceName }}</p>
          </a>
          <a
            v-else
            :href="getServiceUrl(item)"
            :target="item.publicFlag == '1' ? '_blank' : '_self'"
            @click.prevent="isRedirect(item.googleFlag)">
            <img
              :src="`${protocol}//${serviceListUrl}/package.serviceList/images/${serviceImg[item.iconName] || 'Default-Service'}.png`"
              alt="" />
            <p>{{ item.serviceName }}</p>
          </a>
        </div>
      </div>
      <div class="content1"></div>
    </div>
    <login-user-info v-if="showPage"></login-user-info>
  </div>
</template>
<script>
import Bus from '@/assets/js/vueBus'
import Cookies from 'js-cookie'
import { mapState } from 'vuex'
import { getUrlParam, removeCookie } from '@/assets/js/utils'
import Vue from 'vue'
import axios from 'axios'
export default {
  name: 'Success',
  data () {
    return {
      showPage: false,
      email: '',
      name: '',
      session: '',
      serviceList: '',
      userserviceAuthUrl: '',
      userserviceUrl: '',
      isCenter: false,
      protocol: window.location.protocol,
      userManagerUrl: '',
      serviceImg: {
        'User Profile': 'User-Profile',
        'Audio Mixer': 'Audio-Mixer',
        'Default Service': 'Default-Service',
        'Device Config': 'Device-Config',
        'TVU Pack Control': 'TVU-Pack-Control',
        'User Activity': 'User-Activity',
        'Push Live': 'Push-Live',
        'TBS Earthquake Monitor': 'TBS-Earthquake-Monitor',
        'TVU GridLink': 'TVU-GridLink',
        MediaMind: 'MediaMind',
        'Usage Service': 'Usage-Service',
        'Instance Manage': 'Instance-Manage',
        'Config Server': 'Config-Server',
        'General Management': 'General-Management',
        RPS: 'RPS',
        'Server Monitor': 'Server-Monitor',
        'Cloud Live': 'Cloud-Live',
        'Booking Service': 'Booking-Service',
        'RPS Test': 'RPS-Test',
        'DashBoard Service': 'DashBoard-Service',
        'MediaMind Stage': 'MediaMind-Stage',
        'Grid Server': 'Grid-Server',
        'Media Service': 'Media-Service',
        'Scheduler Import': 'Scheduler-Import',
        'TVU Search Stage': 'TVU-Search-Stage',
        'General Management-Test': 'General-Management-Test',
        'Command Center': 'Command-Center',
        Partyline: 'Partyline',
        'TVU Search': 'TVU-Search',
        Producer: 'Producer',
        'Native Graphics': 'Native-Graphics',
        'Paywall APP': 'Paywall-APP',
        PaywallAPP: 'Paywall-APP',
        'TVU Channel': 'TVU-Channel',
        Channel: 'TVU-Channel',
        'TVU Website': 'TVU-Website',
        Commentator: 'Commentator',
        Playout: 'Playout',
        Community: 'Community',
        'Router Center': 'Router-Center',
        'Command Center Beta': 'Command-Center-Beta',
        'Billing Service': 'Billing-Service'
      },
      domainName: ''
    }
  },
  computed: {
    serviceListUrl () {
      return this.$store.state.serviceListUrl || ''
    },
    ...mapState({
      httpsFlag: 'httpsFlag',
      isPc: 'isPc'
    })
  },
  created () {
    const localUrl = window.location.hostname
    const str1 = localUrl.split('.')[1]
    const str2 = localUrl.split('.')[2]
    this.domainName = `.${str1}.${str2}`
    this.session =
      Cookies.get('SID') || localStorage.getItem('session') || ''
    Vue.prototype.$TVU = {
      http: axios,
      serverName: 'TVU networks',
      userAuthUrl: localStorage.getItem('userserviceAuthUrl') && localStorage.getItem('userserviceAuthUrl').split('//')[1],
      session: this.session,
      domain: window.location.origin
    }
    // 从sso页面进入需要在cookie里设置当前用户的tag cookie
    if (getUrlParam('fromPage') === 'sso') {
      // sso登录成功之后设置sid有效
      this.$store.commit('changesValidSid', true)
      this.userTag()
      // this.goSearchPlugin()
    }
  },
  mounted () {
    if (!this.$store.state.validSid) {
      this.$router.push({ name: 'SignInPage' })
      return
    }
    this.userserviceAuthUrl = localStorage.getItem('userserviceAuthUrl')
    this.userserviceUrl = localStorage.getItem('userserviceUrl')
    this.getServiceList()
    Bus.$on('sendUserInfoMessage', (data) => {
      const href = window.location.href
      const flag = href.includes('type=')
      if ((!this.isPc && getUrlParam('fromPage') === 'sso') || flag) this.sendUserInfoToApp(data)
    })
  },
  methods: {
    // 获取用户信息发送给app
    sendUserInfoToApp (data) {
      const u = navigator.userAgent
      const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 // android终端
      if (isAndroid) {
        // 安卓机型
        if (window.Android) {
          Android.getUserInfo(JSON.stringify({
            userId: data.id,
            session: this.session,
            firstName: data.firstName,
            email: data.email
          }))
        }
      } else {
        // ios机型
        if (window.webkit && window.webkit.messageHandlers) {
          window.webkit.messageHandlers.getUserInfo.postMessage(JSON.stringify({
            userId: data.id,
            session: this.session,
            firstName: data.firstName,
            email: data.email
          }))
          // 通知gridlink登录成功
          window.webkit.messageHandlers.hideNavIfLogin.postMessage(JSON.stringify({}))
        }
      }
    },
    async getUserInfo () {
      await this.$axios
        .get(`${this.userserviceAuthUrl}/main/getUserInfoBySession`, {
          params: {
            session: this.session
          }
        }).then(({ data }) => {
          if (data.errorCode == '0x0') {
            // app内不展示本页面，给app发送通知之后会跳转到app内部
            if (!this.isPc && getUrlParam('fromPage') === 'sso') {
              if (window.Android || (window.webkit && window.webkit.messageHandlers)) {
                this.showPage = false
              } else {
                this.showPage = true
              }
            } else {
              this.showPage = true
            }
            let { id, firstName, userName, email } = data.result
            const sourceUrl = encodeURIComponent(window.location.origin + window.location.pathname)
            this.userManagerUrl = `${this.userserviceAuthUrl.replace('userAuth', 'user-group')}/?userId=${id}&session=${this.session}&source=${sourceUrl}&mark=group_user_detail_info#/ug`
            // 获取name和email
            this.email = email
            this.name = firstName || userName || email
            if (getUrlParam('fromPage') === 'sso') {
              this.goSearchPlugin()
            }
          }
        }).catch()
    },
    isRedirect (googleFlag) {
      if (googleFlag) {
        this.$message({
          message:
            'TVU administrator and support accounts must use \'Login with Google\' for access.',
          type: 'error',
          duration: 3000
        })
      }
    },
    goSearchPlugin () {
      if (window.__adobe_cep__) {
        const oneWeekFromNow = new Date()
        oneWeekFromNow.setDate(oneWeekFromNow.getDate() + 30)
        const expires = oneWeekFromNow.toUTCString()
        window.document.cookie = `SID=${this.session};path=/;domain=${this.domainName};secure;samesite=none`
        window.document.cookie = `loginKey=${this.session};expires=${expires};path=/;domain=${this.domainName};secure;samesite=none`
        fetch('/userGroup/user-group/user/setLoginKey', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            uuid: this.session,
            email: this.email,
            type: 1
          })
        }).then(() => {
          const num = history.length
          history.go(1 - num)
        }).catch(() => {
          const num = history.length
          history.go(1 - num)
        })
      }
    },
    getServiceList () {
      // https://10.12.22.101/userAuth/main/ListAccessableService?session=35744FE0C4E347E1B1BA22E70D5BCBF9
      this.$axios
        .get(`${this.userserviceAuthUrl}/main/listBoxServices`, {
          params: {
            session: this.session
          }
        }).then(async (res) => {
          if (res.data.errorCode == '0x0') {
            this.serviceList = []
            await this.getUserInfo()
            this.serviceList.push({
              description: 'User Profile',
              publicFlag: '1',
              googleFlag: false,
              serviceType: 'UserService',
              serviceName: 'User Profile',
              url: this.userManagerUrl
            })
            this.serviceList = this.serviceList.concat(
              res.data.result
            )
            this.$nextTick(() => {
              if (Math.floor((document.querySelector('.serviceListBox').offsetWidth - 180) / 240) > this.serviceList.length) {
                this.isCenter = true
              } else {
                this.isCenter = false
              }
            })
          } else if (res.data.errorCode == '0x80060005') {
            // localStorage.removeItem('session');
            // Bus.$emit('jumpLoginPage');
          }
        })
        .catch()
    },
    getServiceUrl (item) {
      let url = ''

      if (item.serviceName === 'User Profile') {
        return item.url
      }
      if (item.url.indexOf('../') != -1) {
        const _url = item.url.split('..')[1]

        url = this.userserviceUrl + _url + '?session=' + this.session
      } else {
        url = item.url + '?session=' + this.session
      }
      if (url.indexOf('http') == -1) {
        url = 'http://' + url
      }
      return url
    },
    topHeaderCallback (data) {
      if (data.type === 'signOut') {
        this.logout()
      }
    },
    logout () {
      ga('send', 'event', 'newUserService-success-logout', 'click')
      this.$axios.get('/userGroup/user-group/login/logout', {
        params: {
          session: this.session
        }
      }).then((res) => {
        if (res.data.errorCode == '0x0') {
          this.clearCookie()
          Cookies.remove('email', { domain: 'tvunetworks.com' })
          Cookies.remove('firstName', { domain: 'tvunetworks.com' })
          Cookies.remove('userName', { domain: 'tvunetworks.com' })
          localStorage.removeItem('email')
          localStorage.removeItem('firstName')
          localStorage.removeItem('userName')
          localStorage.removeItem('session')
          localStorage.removeItem('userserviceAuthUrl')
          localStorage.removeItem('userserviceUrl')
          Bus.$emit('jumpLoginPage')
        } else {
          this.$message({
            message: 'login defeat',
            type: 'error',
            duration: 3000
          })
        }
      }).catch()
    },
    clearCookie () {
      const exp = new Date()

      exp.setTime(exp.getTime() - 1)
      window.document.cookie = `SID=;path=/;domain=${this.domainName};expires=${exp.toGMTString()};`
      // 失效的sid
      this.$store.state.isClearSid = false
      Bus.$emit('canClearSid')
      this.$store.commit('changesValidSid', false)
    },
    userTag () {
      this.$axios.get('/userGroup/user-group/user/getUserTag').then((res) => {
        if (res.data.errorCode == '0x0') {
          const addTag = res.data.result && res.data.result.addTags
          const delTag = res.data.result && res.data.result.delTags
          // 先删除所有的tag cookie
          if (delTag && delTag.length > 0) {
            delTag.forEach(itm => {
              removeCookie(itm, this.domainName)
            })
          }
          // 再添加当前用户的tagcookie
          if (addTag && addTag.length > 0) {
            addTag.forEach(itm => {
              window.document.cookie = `${itm.tagKey}=${itm.tagValue};path=/;domain=${this.domainName};${this.httpsFlag ? 'secure' : ''}`
            })
          }
        }
      }).catch()
    }
  },
  beforeRouteEnter (to, from, next) {
    if (Cookies.get('SID')) {
      next((vm) => {
        // vm.$store.state.isSuccess = true
      })
    } else {
      // 没有sid，则跳转到signIn页面
      next({ name: 'SignInPage' })
    }
  }
}
</script>
<style lang="less" scoped>
  .successPage {
    height: 100%;
    .contentBox {
      width: 100%;
      height: calc(100% - 66px);
      background: linear-gradient(
        270deg,
        #101010 0%,
        #1d1d1d 50%,
        #101010 100%
      );
      text-align: left;
      position: relative;
      // padding-top: 56px;
      box-sizing: border-box;
      padding: 0 128px;
      .content1 {
        height: calc(100% - 56px);
        max-width: 1058px;
        padding: 26px 12px;
        width: calc(100% - 256px);
        position: absolute;
        top: 0;
        background-repeat: no-repeat;
        background-position-y: 206px;
        background-image: url('../assets/images/dot\ bgm.webp');
        .smallBox {
          margin-top: 64px;
          height: 70px;
          .leftBox {
            display: inline-block;
            width: 70px;
            height: 70px;
            background: rgba(39, 42, 43, 1);
            border-radius: 10px;
            text-align: center;
            line-height: 70px;
            i {
              font-size: 38px;
              color: rgba(67, 74, 84, 1);
            }
          }
          .rightBox {
            height: 100%;
            display: inline-block;
            padding: 4px 0px 4px 24px;
            vertical-align: top;
            .title1 {
              height: 24px;
              font-size: 20px;
              font-weight: bold;
              color: rgba(255, 255, 255, 1);
              line-height: 24px;
            }
            .title2 {
              height: 24px;
              font-size: 20px;
              font-weight: bold;
              color: rgba(144, 144, 144, 1);
              line-height: 24px;
              margin-top: 14px;
            }
          }
        }
      }
      .titleBox {
        position: absolute;
        top: 0;
        background-image: url('../assets/images/welcome.png');
        width: calc(100% - 256px);
        height: 300px;
        .bigTitle {
          margin-top: 60px;
          height: 60px;
          font-size: 50px;
          font-weight: bold;
          color: rgba(255, 255, 255, 1);
          line-height: 60px;
          background: linear-gradient(
            180deg,
            rgba(188, 188, 188, 1) 0%,
            rgba(255, 255, 255, 1) 43%,
            rgba(188, 188, 188, 1) 100%
          );
          background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        .smallTitle {
          height: 48px;
          width: 631px;
          font-size: 20px;
          color: rgba(144, 144, 144, 1);
          line-height: 24px;
          margin: 28px 0 10px 0;
        }
      }
      .serviceListBox:after {
        display: block;
        content: '';
        // width: 30%;
        height: 0px;
      }
      .center.serviceListBox {
        justify-content: center;
      }
      .serviceListBox {
        position: absolute;
        top: 300px;
        width: calc(100% - 256px);
        height: calc(100% - 300px);
        padding: 20px 90px;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        overflow-y: scroll;
        z-index: 10;
        a:hover {
          cursor: pointer;
        }

        a {
          text-align: center;
          display: inline-block;
          height: 200px;
          width: 200px;
          margin: 20px;
          background: #111111;
          img {
            // height: 80px;
            // width: 80px;
            margin-top: 44px;
          }
          p {
            text-align: center;
            margin-top: 5px;
            font-size: 16px;
            font-weight: 500;
            color: #77aa44;
          }
        }
      }
    }
  }
  .scrollCss::-webkit-scrollbar {
    width: 6px;
  }
  .scrollCss::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #626262;
  }
  .successPage-mobile {
    padding: 0;
    .contentBox {
      width: 100%;
      padding: 0 24px;
      .content1 {
        width: calc(100% - 48px);
        padding: 0;
      }
      .titleBox {
        width: calc(100% - 48px);
        height: 145px;
        .bigTitle {
          margin-top: 30px;
          font-size: 32px;
          line-height: 24px;
        }
        .smallTitle {
          width: 100%;
          font-size: 16px;
          margin: 0;
        }
      }
      .serviceListBox {
        width: calc(100% - 48px);
        height: calc(100% - 165px);
        padding: 20px 0;
        top: 145px;
        display: inline-block;
        overflow-y: scroll;
        .box-item {
          width: 40%;
          float: left;
          margin-left: 10px;
        }
        a {
          width: 100%;
          height: 115px;
          margin: 10px;
          img {
            margin-top: 10px;
          }
        }
      }
    }
  }
  /deep/.el-loading-mask {
    background-color: #000000;
  }
</style>
<style lang="less">
.successPage-mobile {
  #tvu-login-user-info {
    .know-info {
      width: 80% !important;
      right: 40px;
    }
    .el-dialog {
      width: 90% !important;
    }
  }
}
</style>
